import React from 'react';
import {useNavigate} from 'react-router-dom';
import * as Routes from '../../../Constants/Routes';
import { FaPlayCircle } from "react-icons/fa";

export const CarouselElement = ({ CarouselItem }) => {

	const navigate = useNavigate();

	function goToNews(News){
		if(News.newstype === 3){
			navigate(`${Routes.VIDEO1}/${News.id}`)
		}else{
			navigate(`${Routes.NEWS1}/${News.id}`)
		}
	}


	return (
		<div className='carouselItemContainer' onClick={()=> {goToNews(CarouselItem)}}>
		{(CarouselItem.newstype === 3) &&<p className='videoSymbol'><FaPlayCircle/></p>}
			<div className='imgCont'>
				<img
					src={CarouselItem.imageURL}
					alt={CarouselItem.title}
					className='carouselImage'
				/>
				<p className='carouselItemText'>{CarouselItem.title}</p>
			</div>
		</div>
	);
};
