import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as URLS from '../../Constants/URLs';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import AdContainer from './AdContainer';
import { Helmet } from "react-helmet";
import HelmetMetaData from './HelmetMetaData'
import {
	FacebookIcon,
	TwitterIcon,
	WhatsappIcon,
	TwitterShareButton,
	FacebookShareButton,
	WhatsappShareButton
} from 'react-share';

const SingleNews = () => {
	let [ IsFirstLoad ] = useState(true);
	let [ News, setNews ] = useState(() => {
		return {
			title: '',
			videoURL: '',
			created_at: null,
			imageURL: '',
			body: ''
		};
	});
	let [ Ads, setAds ] = useState(() => {
		return []
	});

	const Urlparams = useParams();
	const PageUrl = 'https://alaayn3.com/news/';
	const FBSharer= 'https://www.facebook.com/sharer.php?u='

	useEffect(
		() => {
			if (IsFirstLoad) {
				GetNewsByID();
			}
		},
		[ IsFirstLoad ]
	);

	function GetNewsByID() {
		axios({
			method: 'get',
			url: `${URLS.SERVER_URL}GetNewsByID/${Urlparams.id}`,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then((response) => {
				if (response.data) {
					var responseJson = response.data;
					//alert(JSON.stringify(responseJson))
					setNews(responseJson.News);
					setAds(responseJson.Ads)
					//notify(`Welcome ${responseJson.FirstName} ${responseJson.LastName}`);
					if (response.status === 201) {
					} else {
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	return (
		<main className="p-0 m-0">
		<Helmet>
			<title>{News.title}</title>
			<meta property="og:title" content={News.title} />
			<meta property="og:type" content="website" />
			<meta property="og:url" content={`${PageUrl}${Urlparams.id}`} />
			<meta property="og:image" content={News.imageURL} />
		</Helmet>
			<div className="row p-0 m-0">
				<div className="col-md-3 col-sm-1" />
				<div className="col-md-6 col-sm-10">
					<div className="row justify-content-center">
						<img src={News.imageURL} alt="" className="NewsImage" />
					</div>
					<div className="d-flex flex-row justify-content-center">
						<div className="m-2">
							<TwitterShareButton url={`${PageUrl}${Urlparams.id}`}>
								<TwitterIcon size={32} round={true} />
							</TwitterShareButton>
						</div>
						<div className="m-2">
							<FacebookShareButton url={`${PageUrl}${Urlparams.id}`} quote={News.title} imageurl={News.imageURL}>
								<FacebookIcon size={32} round={true} />
							</FacebookShareButton>
							{/*<a href={`${FBSharer}${PageUrl}${Urlparams.id}?imageurl=${News.imageURL}&title=${News.title}`}><FacebookIcon size={32} round={true} /></a>
	<a target='_blank' href={`https://www.facebook.com/sharer.php?u=${PageUrl}${Urlparams.id}&title=${News.title}`}><FacebookIcon size={32} round={true} /></a>*/}

						</div>
						<div className="m-2">
							<WhatsappShareButton url={`${PageUrl}${Urlparams.id}`}>
								<WhatsappIcon size={32} round={true} />
							</WhatsappShareButton>
						</div>
					</div>
				</div>
				<div className="col-md-3 col-sm-1" />
			</div>
			<div className="row p-0 m-0">
				<div className="col-md-2 col-sm-2" />
				<div className="col-md-8 col-sm-8 lineBreaks NewsText">
					<div className="row NewsTitle mt-3 p-3">
						{News.title}{' '}
						<span className="NewsDate p-0 m-0">
							{News.created_at != null ? moment(News.created_at).format('DD/MM/YYYY') : ''}
						</span>
					</div>
					<div className="row mt-3 p-3">{News.body}</div>
					<div className="row justify-content-start p-0 m-0 mt-3">{Ads.length > 0 && <AdContainer imgSrc={Ads[0].imageURL}/>}</div>
					
					<div className="row mt-3 p-3">{News.body2}</div>
					<div className="row p-0 m-0 mt-3">{Ads.length > 1 && <AdContainer imgSrc={Ads[1].imageURL}/>}</div>
					<div className="row mt-3 p-3">{News.body3}</div>
				</div>

				<div className="col-md-2 col-sm-2" />
			</div>
		</main>
	);
};

export default SingleNews;
