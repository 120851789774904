import { BrowserRouter  as Router, Route, Routes } from "react-router-dom";
import Navigation from './Components/Navigation/Navigation';
import Footer from './Components/Footer/Footer';
import HomePage from './Components/HomePage/HomePage.jsx';
import SingleNews from './Components/HomePage/SingleNews';
import AboutUs from './Components/AboutUs/AboutUs';
import Admin from './Components/Admin/Admin';
import * as NavRoutes from './Constants/Routes';
import VideoPage from "./Components/HomePage/VideoPage";
import EditNewsPage from "./Components/Admin/EditNewsPage";
import { AuthenticationProvider } from './Contexts/Authentication.context'
import './App.css';

function App() {

  const User = sessionStorage.getItem('User')

  return (
    <AuthenticationProvider User={User}>
    <div className="fullpage p-0">
      <Router>
          <Navigation />
          <div className='fullpage1'>
          <Routes>
          <Route
            exact
            path={NavRoutes.HOMEPAGE}
            element={<HomePage/>}
          />
          <Route
            exact
            path={NavRoutes.ABOUTUSPAGE}
            element={<AboutUs/>}
          />
          <Route
            exact
            path={NavRoutes.ADMINPAGE}
            element={<Admin/>}
          />
          <Route
            exact
            path={NavRoutes.NEWS}
            element={<SingleNews/>}
          />
          <Route
            exact
            path={NavRoutes.VIDEO}
            element={<VideoPage/>}
          />
          <Route
            exact
            path={NavRoutes.EDITNEWSPAGE}
            element={<EditNewsPage/>}
          />
          
          </Routes>
          <Footer />
          </div>
      </Router>
    </div>
    </AuthenticationProvider>
  );
}

export default App;
