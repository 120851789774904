import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import * as URLS from '../../Constants/URLs';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as Routes from '../../Constants/Routes';
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import confirm from "reactstrap-confirm";

export default function AllAds() {
	const [ Ads, setAds ] = useState([]);

    useEffect(
		() => {
      GetAdsForAdmin();
		},
		[]
	);

  function GetAdsForAdmin() {
		axios({
			method: 'get',
			url: `${URLS.SERVER_URL}GetAdsForAdmin`,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then((response) => {
				if (response.data) {
					let Ads = response.data.Ads;
          console.log(Ads)
          setAds(Ads);
					if (response.status === 201) {
					} else {
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}
  
  async function alertDialog (id) {
    let result = await confirm({
        title: '',
        message:
            'هل تريد الحذف؟',
        cancelText: 'كلا',
        confirmText: 'نعم',
    });

    if(result) DeleteAd(id)
};

function DeleteAd(id){
  axios({
    method: 'get',
    url: `${URLS.SERVER_URL}DeleteAd/${id}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then((response) => {
      if (response.data) {
        var responseJson = response.data;
        //notify(`Welcome ${responseJson.FirstName} ${responseJson.LastName}`);
        if (responseJson.success === true) {
          GetAdsForAdmin();
        } else {
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

  return <div className='row w-100'>
  {Ads.map(ad =>(
    <div className="row mt-3" key={ad.id}>
      <div className="col-md-9"><div className="row justify-content-center"><img src={ad.imageURL} alt='Advertisement' className='w-25'/></div></div>
      <div className="col-md-3"><button className='bg-transparent border-0 d-inline' onClick={() => alertDialog(ad.id)}><FaTrashAlt/></button></div>
    </div>
  ))}
  </div>;
}
