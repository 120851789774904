import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import axios from 'axios';
import * as URLS from '../../../Constants/URLs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DisplayNewsNumber = () => {
	let [ IsFirstLoad, setIsFirstLoad ] = useState(true);

	useEffect(
		() => {
			if (IsFirstLoad) {
				setIsFirstLoad(false);
				getNumberOfNews();
			}
		},
		[ IsFirstLoad ]
	);
	const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();

	function getNumberOfNews() {
		axios({
			method: 'get',
			url: `${URLS.SERVER_URL}GetConfiguration/NumberOfNews`,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then((response) => {
				if (response.data) {
					var responseJson = response.data;
					if (response.status === 201) {
						setValue('NumberOfNews', parseInt(responseJson.config.value, 10));
					} else {
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	const onSubmit = (data) => {
		let params = {
			key: 'NumberOfNews',
			value: `${data.NumberOfNews}`
		};
		axios({
			method: 'post',
			url: `${URLS.SERVER_URL}UpdateConfiguration`,
			data: JSON.stringify(params),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then((response) => {
				if (response.data) {
					var responseJson = response.data;
					if (response.status === 201) {
						notify(`${responseJson.config.message}`);
					} else {
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const notify = (msg) => toast(msg);

	return (
		<main className="w-100 p-0">
			<ToastContainer />
			<div className="row p-0 m-0 pt-5">
				<div className="col-md-2 col-sm-1" />
				<div className="col-md-8 col-sm-10">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
                        <label className='p-0 m-0 mb-2'>عدد الأخبار</label>
							<div className="col-6">
								<div className="row">
									<input
										className="signInInputs numberInputs"
										{...register('NumberOfNews', { required: true, pattern: /[0-9]/, min: 1 })}
										placeholder="عدد الأخبار"
										type="number"
									/>
								</div>

								<div className="row">
									{errors.NumberOfNews ? (
										<p>
											{errors.NumberOfNews.type === 'required' && (
												<span className="text-danger">الرجاء إدخال عدد الأخبار</span>
											)}
											{errors.NumberOfNews.type === 'pattern' && (
												<span className="text-danger">الرجاء إدخال أرقام فقط</span>
											)}
											{errors.NumberOfNews.type === 'min' && (
												<span className="text-danger">لا يمكن إدخال رقم اصغر من 1</span>
											)}
										</p>
									) : null}
								</div>
							</div>
							<div className="col-6">
								<div className="row justify-content-end">
									<input type="submit" value="حفظ" className="submitBtn" />
								</div>
							</div>
						</div>
					</form>
				</div>
				<div className="col-md-2 col-sm-1" />
			</div>
		</main>
	);
};

export default DisplayNewsNumber;
