import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as URLS from '../../Constants/URLs';
import moment from 'moment';
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import confirm from "reactstrap-confirm";
import {useNavigate} from 'react-router-dom';
import * as Routes from '../../Constants/Routes';


const SeeAllNews = () => {
	let [ News, setNews ] = useState([]);
	const navigate = useNavigate();

	useEffect(
		() => {
			GetNewsForAdmin();
		},
		[]
	);

	function GetNewsForAdmin() {
		axios({
			method: 'get',
			url: `${URLS.SERVER_URL}GetNewsForAdmin`,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then((response) => {
				if (response.data) {
					var responseJson = response.data;
					setNews(responseJson.News);
					//notify(`Welcome ${responseJson.FirstName} ${responseJson.LastName}`);
					if (response.status === 201) {
					} else {
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

    function EditNews(id){
        navigate(`${Routes.EDITNEWSPAGE1}/${id}`)
    }

    function DeleteNews(id){
        axios({
			method: 'get',
			url: `${URLS.SERVER_URL}DeleteNews/${id}`,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then((response) => {
				if (response.data) {
					var responseJson = response.data;
					//notify(`Welcome ${responseJson.FirstName} ${responseJson.LastName}`);
					if (responseJson.success === true) {
                        GetNewsForAdmin();
					} else {
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
    }

    async function alertDialog (id) {
        let result = await confirm({
            title: '',
            message:
                'هل تريد الحذف؟',
            cancelText: 'كلا',
            confirmText: 'نعم',
        });

        if(result) DeleteNews(id)
    };

	return (
		<section className="container-fluid p-0 m-0">
			<div className="row w-100 p-0 m-0">
				<div className="col-md-2 col-sm-1" />
				<div className="col-md-8 col-sm-10">
					<div className="row pt-5 pb-5">
						<div className="col-md-4 col-sm-4">عنوان الخبر</div>
						<div className="col-md-4 col-sm-4">تاريخ الخبر</div>
						<div className="col-md-4 col-sm-4">عدد المشاهدات</div>
					</div>
				</div>
				<div className="col-md-2 col-sm-1" />
			</div>
			{News.map((news, index) => (
				<div key={news.id} className="row pt-2 pb-2 w-100 p-0 m-0 list">
					<div className="col-md-2 col-sm-1" />
					<div className="col-md-8 col-sm-10">
						<div className="row">
							<div className="col-md-3 col-sm-3">{news.title}</div>
							<div className="col-md-3 col-sm-3">{moment(news.created_at).format('DD/MM/YYYY')}</div>
							<div className="col-md-3 col-sm-3">{news.views}</div>
							<div className="col-md-3 col-sm-3">
								<div className="flex-row">
									<button className='bg-transparent border-0 d-inline' onClick={() => EditNews(news.id)}><FaEdit/></button>
									<button className='bg-transparent border-0 d-inline' onClick={() => alertDialog(news.id)}><FaTrashAlt/></button>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-2 col-sm-1" />
				</div>
			))}
		</section>
	);
};

export default SeeAllNews;
