import React, { useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import * as Routes from '../../../Constants/Routes';
import { FaPlayCircle } from "react-icons/fa";
import moment from 'moment';
//
export const GridItem = ({ News }) => {

	const navigate = useNavigate();

	function goToNews(News){
		if(News.newstype === 3){
			navigate(`${Routes.VIDEO1}/${News.id}`)
		}else{
			navigate(`${Routes.NEWS1}/${News.id}`)
		}
	}

	return (
		<div className="col-lg-4 col-md-6 col-sm-12 mt-4 mb-4 newsItem">
		{(News.newstype === 3) &&<p className='videoSymbol'><FaPlayCircle/></p>}
			<div className="row p-0 m-0" role='button' onClick={()=> {goToNews(News)}}>
				<div className="col-5 col-md-12 col-sm-5 col-xs-5 imgCont">
					<img
						src={News.imageURL}
						alt={News.title}
						className='carouselImage'
					/>
				</div>
				<div className="col-7 col-md-12 col-sm-7 col-xs-7 NewsTitle">{News.title}</div>
				<div className="col-7 col-md-12 col-sm-7 col-xs-7 NewsDate">{News.created_at != null ? moment(News.created_at).format('DD/MM/YYYY') : ''}</div>
			</div>
		</div>
	);
};
