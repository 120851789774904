import React from 'react'


const AboutUs = () =>{
    return(
        <main className='fullpage'>
        <p
          className='text-center w-100 fs-2 fw-bold'
        >
          العين{' '}
        </p>
      </main>
    )
}

export default AboutUs