import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import axios from 'axios';
import * as URLS from '../../Constants/URLs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddNews = () => {
	const [ image, setImage ] = useState(null);
	const [ IsVideo, setIsVideo ] = useState(false);

	const { register, handleSubmit, reset, formState: { errors } } = useForm();

	const onSubmit = (data) => {
		const formData = new FormData();
		//FILE INFO NAME WILL BE "my-image-file"
		formData.append('file', data.newsimage[0]);
		formData.append('title', data.Title);
		formData.append('body', data.body);
		formData.append('body2', data.body2);
		formData.append('body3', data.body3);
		formData.append('newstype', data.newstype);
		formData.append('videoURL', data.VideoUrl);
		axios({
			method: 'post',
			url: `${URLS.SERVER_URL}AddNews`,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then((response) => {
				if (response.data) {
					var responseJson = response.data;
					notify(responseJson.message);
					reset();
					//notify(`Welcome ${responseJson.FirstName} ${responseJson.LastName}`);
					if (response.status === 201) {
					} else {
					}
				}
			})
			.catch((error) => {
				alert(JSON.stringify(error));
				console.log(error);
			});
	};

	const getFileInfo = (e) => {
		//NOTE THE ADDITION OF 'e' PARAMETER
		setImage(e.target.files[0]);
	};

	const notify = (msg) => toast(msg);

	function changeVidType(e) {
		if (parseInt(e.target.value, 10) === 3) setIsVideo(true);
		else setIsVideo(false);
	}

	return (
		<section className="row w-100 p-0 m-0">
			<ToastContainer />
			<div className="row m-0">
				<div className="col-md-3 col-sm-2" />
				<div className="col-md-6 col-sm-2">
					<form onSubmit={handleSubmit(onSubmit)}>
						{/* register your input into the hook by invoking the "register" function */}
						<div className="row">
							<div className="col-12">
								<label>عنوان الخبر</label>
								<input
									className="signInInputs"
									placeholder="عنوان الخبر"
									{...register('Title', { required: true })}
								/>
							</div>
							<div className="col-12">
								{errors.Title && <span className="text-danger">الرجاء إدخال عنوان الخبر</span>}
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<label className="d-block">صورة الخبر</label>
								<input
									className="signInInputs"
									type="file"
									{...register('newsimage', { required: true })}
									data-buttonText="صورة الخبر"
								/>
							</div>
							<div className="col-12">
								{errors.newsimage && <span className="text-danger">الرجاء إدخال صورة الخبر</span>}
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<label className="d-block">نوع الخبر</label>
								<select className="signInInputs" {...register('newstype')} onChange={changeVidType}>
									<option value={1} className="upper">
										خبر ثابت
									</option>
									<option value={2} className="upper">
										خبر متحرك
									</option>
									<option value={3} className="upper">
										فيديو
									</option>
								</select>
							</div>
						</div>

						{IsVideo && (
							<div className="row">
								<div className="col-12">
									<label className="d-block">رابط الفيديو</label>
									<input
										className="signInInputs"
										placeholder="رابط الفيديو"
										{...register('VideoUrl', { required: IsVideo })}
									/>
								</div>
								<div className="col-12">
									{errors.VideoUrl && <span className="text-danger">الرجاء إدخال رابط الفيديو</span>}
								</div>
							</div>
						)}

						{/* include validation with required or other standard HTML validation rules */}

						<label>النصّ</label>
						<div className="row">
							<div className="col-12">
								{!IsVideo && <label>جزء1</label>}
								<textarea
									className="signInInputs"
									{...register('body', { required: !IsVideo })}
									placeholder="نصّ الخبر"
									type=""
									rows={15}
								/>
							</div>
							{/* errors will return when field validation fails  */}

							<div className="col-12">
								{errors.body && <span className="text-danger">الرجاء إدخال نصّ الخبر</span>}
							</div>

							{!IsVideo && (
								<div className="col-12">
									<label>جزء2</label>
									<textarea
										className="signInInputs"
										{...register('body2')}
										placeholder="نصّ الخبر"
										type=""
										rows={15}
									/>
								</div>
							)}
							{!IsVideo && (
								<div className="col-12">
									<label>جزء3</label>
									<textarea
										className="signInInputs"
										{...register('body3')}
										placeholder="نصّ الخبر"
										type=""
										rows={15}
									/>
								</div>
							)}
						</div>

						<div className="row justify-content-center">
							<input type="submit" value="حفظ" className="submitBtn" />
						</div>
					</form>
				</div>
				<div className="col-md-3 col-sm-2" />
			</div>
		</section>
	);
};

export default AddNews;
