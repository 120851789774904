import React, { useState, useEffect } from 'react';
import AdminSignIn from './AdminSignIn';
import AdminTabs from './AdminTabs';
import { useAuth } from '../../Contexts/Authentication.context'

const Admin = () => {
	let [ IsSignedIn, setIsSignedIn ] = useState(false);
	let [ User, setUser ] = useState(false);
	const { currentUser } = useAuth();

	function UserSignedIn(status, User) {
		setIsSignedIn(status);
		setUser(User);
	}

	return (
		<main className="w-100 p-0">
			{!currentUser ? <AdminSignIn UserSignedIn={UserSignedIn} /> : <AdminTabs />}
		</main>
	);
};

export default Admin;
