import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import axios from 'axios';
import * as URLS from '../../Constants/URLs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdsPage = () => {

	const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();

	const onSubmit = (data) => {
		const formData = new FormData();
		//FILE INFO NAME WILL BE "my-image-file"
		formData.append('file', data.newsimage[0]);
		formData.append('title', "a");
		axios({
			method: 'post',
			url: `${URLS.SERVER_URL}AddAds`,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then((response) => {
				if (response.data) {
					var responseJson = response.data;
					notify(responseJson.message);
					reset();
					//notify(`Welcome ${responseJson.FirstName} ${responseJson.LastName}`);
					if (response.status === 201) {
					} else {
					}
				}
			})
			.catch((error) => {
				alert(JSON.stringify(error));
				console.log(error);
			});
	};

	const notify = (msg) => toast(msg);
	return (
		<section className="row w-100 p-0 m-0">
			<ToastContainer />
			<div className="row m-0">
				<div className="col-md-3 col-sm-2" />
				<div className="col-md-6 col-sm-2">
					<form onSubmit={handleSubmit(onSubmit)}>
						{/* register your input into the hook by invoking the "register" function */}
						<div className="row">
							<div className="col-12">
								<label className="d-block">صورة الإعلان</label>
								<input
									className="signInInputs"
									type="file"
									{...register('newsimage', { required: true })}
									data-buttonText="صورة الإعلان"
								/>
							</div>
							<div className="col-12">
								{errors.newsimage && <span className="text-danger">الرجاء إدخال صورة الإعلان</span>}
							</div>
						</div>

						{/* include validation with required or other standard HTML validation rules */}

						<div className="row justify-content-center">
							<input type="submit" value="حفظ" className="submitBtn" />
						</div>
					</form>
				</div>
				<div className="col-md-3 col-sm-2" />
			</div>
		</section>
        )
}
export default AdsPage;