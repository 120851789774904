import React from 'react'
import {CarouselContainer} from '../HomePageComponents/Carousel/CarouselContainer'
import {NewsGrid} from '../HomePageComponents/NewsGrid/NewsGrid'


const HomePage = () =>{
    return(
        <div className="p-0 m-0">
			<main className="p-0 m-0">
				<div className="row p-0 m-0">
					<CarouselContainer />
				</div>
				<div className="row p-0 m-0">
					<NewsGrid />
				</div>
			</main>
		</div>
    )
}

export default HomePage