import React, { useState } from 'react';

const AuthenticationContext = React.createContext(null);

export const AuthenticationProvider = ({ user, children }) =>{
    const [currentUser, setCurrentUser] = useState(user);

    return(
        <AuthenticationContext.Provider value= {{ currentUser, setCurrentUser }}>
        {children}
        </AuthenticationContext.Provider>
    )
}

export const useAuth = () => React.useContext(AuthenticationContext)