import React, { useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { CarouselElement } from './CarouselElement';
import axios from 'axios';
import * as URLS from '../../../Constants/URLs';

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
		slidesToSlide: 1, // optional, default to 1.
		partialVisibilityGutter: 40
	},
	tablet: {
		breakpoint: { max: 1024, min: 550 },
		items: 2,
		slidesToSlide: 1, // optional, default to 1.
		partialVisibilityGutter: 40
	},
	mobile: {
		breakpoint: { max: 550, min: 0 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
		partialVisibilityGutter: 40
	}
};

export const CarouselContainer = () => {

	let [IsFirstLoad, setIsFirstLoad] = useState(true);
    let [News, setNews] = useState([]);

    useEffect(() => {
        if(IsFirstLoad){
            setIsFirstLoad(false);
            getNews();
        }
    }, [IsFirstLoad])

    function getNews(){
        axios({
            method: 'get',
            url: `${URLS.SERVER_URL}GetCarouselNews`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (response.data) {
                    var responseJson = response.data;
                    setNews(responseJson.News)
                    //notify(`Welcome ${responseJson.FirstName} ${responseJson.LastName}`);
                    if (response.status === 201) {
                    } else {
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    
	return (
		<section className="m-0 p-0">
			<div className="row p-0 m-0">
				<div className="col-md-1 col-sm-12" />
				<div className="col-md-10 col-sm-12">
					<div className='carouselContainer'>
						<Carousel
							responsive={responsive}
							showDots={false}
							infinite={true}
							autoPlay={true}
							autoPlaySpeed={2000}
							arrows={false}
						>
							{News.map((item, index) => <CarouselElement key={index} CarouselItem={item} />)}
						</Carousel>
					</div>
				</div>
				<div className="col-md-1 col-sm-12" />
			</div>
		</section>
	);
};
