import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import axios from 'axios';
import * as URLS from '../../../Constants/URLs';
import DisplayNewsNumber from './DisplayNewsNumber'

const Configurations = () => {
	return (
		<main className="w-100 p-0">

		<DisplayNewsNumber/>
		</main>
	);
};

export default Configurations;
