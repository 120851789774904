import React, { useState, useEffect } from 'react';
import { GridItem } from './GridItem';
import axios from 'axios';
import * as URLS from '../../../Constants/URLs';

export const NewsGrid = () => {

	let [IsFirstLoad, setIsFirstLoad] = useState(true);
    let [News, setNews] = useState([]);

    useEffect(() => {
        if(IsFirstLoad){
            setIsFirstLoad(false);
            getNews();
        }
    }, [IsFirstLoad])

    function getNews(){
        axios({
            method: 'get',
            url: `${URLS.SERVER_URL}GetNews`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (response.data) {
                    var responseJson = response.data;
                    setNews(responseJson.News)
                    if (response.status === 201) {
                    } else {
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


	return (
		<section className="container-fluid mt-5 p-0">
			<div className="row p-0 m-0">
				<div className="col-md-2 col-sm-1" />
				<div className="col-md-8 col-sm-10">
					<div className="row">{News.map((news, index) => <GridItem key={index} News={news} />)}</div>
				</div>
				<div className="col-md-2 col-sm-1" />
			</div>
		</section>
	);
};
