import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, Nav, NavLink, NavbarBrand } from 'reactstrap';
import * as Routes from '../../Constants/Routes';
import Logo from '../../Assets/Logo/g37.png';

const Navigation = () => {
	const [ NavigationOpen, setNavigationOpen ] = useState(false);
	function toggleNavigation() {
		setNavigationOpen(!NavigationOpen);
	}
	return (
		<div>
			<Navbar className="navigationbar justify-content-around" expand="md">
				<NavbarBrand href="/" className="">
					<img src={Logo} alt="Alaayn3.com" width="150px" />
				</NavbarBrand>
				{/*<NavbarToggler onClick={toggleNavigation} className="navtoggler" />
				<Collapse isOpen={NavigationOpen} navbar className="pl-5 pr-5 collapseCls">
					<Nav
						className="w-100 pl-5 pr-5 fw-bold flex-md-row-reverse flex-sm-col justify-content-around text-end"
						navbar
					>
						<NavLink href={Routes.HOMEPAGE} className="subLink" onClick={() => setNavigationOpen(false)}>
							صفحة الأخبار
						</NavLink>
						<NavLink href={Routes.ABOUTUSPAGE} className="subLink" onClick={() => setNavigationOpen(false)}>
							نبذة عَنّا
						</NavLink>
						<NavLink href={Routes.ADMINPAGE} className="subLink" onClick={() => setNavigationOpen(false)}>
							Admin
						</NavLink>
					</Nav>
	</Collapse>*/}
			</Navbar>
		</div>
	);
};

export default Navigation;
