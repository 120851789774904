import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import axios from 'axios';
import * as URLS from '../../Constants/URLs';
import { useAuth } from '../../Contexts/Authentication.context';


const AdminSignIn = ({UserSignedIn}) => {

    const { setCurrentUser } = useAuth()

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
    let params = {
        email: data.Email,
        password: data.Password
    };
    axios({
        method: 'post',
        url: `${URLS.SERVER_URL}signin`,
        data: JSON.stringify(params),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((response) => {
            if (response.data) {
                var responseJson = response.data;
                //notify(`Welcome ${responseJson.FirstName} ${responseJson.LastName}`);
                if (response.status === 201) {
                    let User = responseJson.user;
                    UserSignedIn(true, User);
                    setCurrentUser(User);
                    sessionStorage.setItem('User', User)
                } else {
                    UserSignedIn(false, {})
                    setCurrentUser({})
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
};


	return (
		<section>
			<div className="row p-0 m-0 pt-5">
            <div className='row mt-5'>
            <h1 className='text-center'>
            تسجيل الدخول</h1>
            </div>
				<div className="col-md-4 col-sm-2" />
				<div className="col-md-4 col-sm-8">
					<form onSubmit={handleSubmit(onSubmit)}>
						{/* register your input into the hook by invoking the "register" function */}
                        <div className='row p-0 m-0'></div>
						<input className='signInInputs' placeholder='youremail@example.com' {...register('Email')} />

						{/* include validation with required or other standard HTML validation rules */}

                        <div className='row'>
                        <div className='col-12'>
						<input className='signInInputs' {...register('Password', { required: true })} placeholder='كلمة السر' type='password'/></div>
						{/* errors will return when field validation fails  */}
                        
                        <div className='col-12'>
						{errors.exampleRequired && <span className='text-danger'>الرجاء إدخال كلمة السر</span>}</div>
                        </div>

                        <div className='row justify-content-center'>

						<input type="submit" value='دخول' className='submitBtn' /></div>
					</form>
				</div>
				<div className="col-md-4 col-sm-2" />
			</div>
		</section>
	);
};

export default AdminSignIn;
