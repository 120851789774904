import React from 'react';
import { Link } from "react-router-dom";
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaFacebook } from 'react-icons/fa';
import { IoIosMail } from "react-icons/io";



const Footer = () =>{
    return(
        <footer className='footer mt-4 p-4'>
        <div className="d-flex p-0 m-0 justify-content-around">
        <a className='FooterLinks'
            href='https://www.facebook.com/alaayn3/'
            target='_blank'
            rel='noreferrer'
        >
        <FaFacebook/>
        </a>
        <a className='FooterLinks'
            href='03410733'
            target='_blank'
            rel='noreferrer'
        >
        <BsFillTelephoneFill/>
        </a>
        <a className='FooterLinks'
            href='mailto:alaayn3@gmail.com'
            target='_blank'
            rel='noreferrer'
        >
        <IoIosMail />
        </a>
        </div>
      </footer>
    )
}

export default Footer