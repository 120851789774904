import React, { useEffect, useState } from 'react';
import AddNews from './AddNews';
import Configurations from './Configurations/Configurations';
import SeeAllNews from './SeeAllNews';
import AllAds from './AllAds.jsx'
import AdsPage from './AdsPage';


const AdminTabs = () =>{

	let [ SelectedTab, setSelectedTab ] = useState(() => {
        return 1});

    return(
      <main className="tabs-page">
        <div className="tabs-titles-container">
          <div
            className={`tab-title no-right-border ${SelectedTab === 1 ? 'tab-title-active' : null}`}
            onClick={() => {
              setSelectedTab(1);
            }}
          >
          خبر جديد
          </div>
          <div
            className={`tab-title no-right-border ${SelectedTab === 2 ? 'tab-title-active' : null}`}
            onClick={() => {
              setSelectedTab(2);
            }}
          >
          إعلان جديد
          </div>
          <div
            className={`tab-title no-right-border ${SelectedTab === 3 ? 'tab-title-active' : null}`}
            onClick={() => {
              setSelectedTab(3);
            }}
          >
          كل الأخبار
          </div>
          <div
            className={`tab-title no-right-border ${SelectedTab === 4 ? 'tab-title-active' : null}`}
            onClick={() => {
              setSelectedTab(4);
            }}
          >
          كل الإعلانات
          </div>
          <div
            className={`tab-title no-right-border ${SelectedTab === 5 ? 'tab-title-active' : null}`}
            onClick={() => {
              setSelectedTab(5);
            }}
          >
            الإعدادات
          </div>
        </div>
        <div className="tab-content-container">
          {SelectedTab === 1 ? (
            <AddNews/>
          ) : SelectedTab === 2 ? (
            <AdsPage/>
          ) : SelectedTab === 3 ? (
            <SeeAllNews/>
          ) : SelectedTab === 4 ? (
            <AllAds/>
          ) : SelectedTab === 5 ? (            
            <Configurations />
          ) : null}
        </div>
      </main>
    );
}

export default AdminTabs