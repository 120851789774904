import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as URLS from '../../Constants/URLs';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
	FacebookIcon,
	TwitterIcon,
	WhatsappIcon,
	TwitterShareButton,
	FacebookShareButton,
	WhatsappShareButton
} from 'react-share';
import AdContainer from './AdContainer';

const VideoPage = () => {
	let [ IsFirstLoad, setIsFirstLoad ] = useState(true);
	let [ News, setNews ] = useState(() => {
		return {
			title: '',
			videoURL: ''
		};
	});
	let [ Ads, setAds ] = useState(() => {
		return []
	});

	const Urlparams = useParams();
	const PageUrl = 'https://alaayn3.com/video/';
	const EmbedVid = 'https://www.youtube.com/embed/';

	useEffect(
		() => {
			if (IsFirstLoad) {
				setIsFirstLoad(false);
				GetNewsByID();
			}
		},
		[ IsFirstLoad ]
	);

	function GetNewsByID() {
		axios({
			method: 'get',
			url: `${URLS.SERVER_URL}GetVideoByID/${Urlparams.id}`,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then((response) => {
				if (response.data) {
					var responseJson = response.data;
					//alert(JSON.stringify(responseJson))
					setNews(responseJson.News);
					setAds(responseJson.Ads);
					//notify(`Welcome ${responseJson.FirstName} ${responseJson.LastName}`);
					if (response.status === 201) {
					} else {
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	return (
		<section className="row w-100 p-0 m-0 justify-content-center mt-5">
		
			<div className="row">
				<div className="col-md-2 col-sm-1" />
				<div className="col-md-8 col-sm-10">				
				<div className="row p-3 pt-0 pb-0 NewsTitle">{News.title}</div>
				<div className="row p-3 pt-0 NewsDate">{News.created_at != null ? moment(News.created_at).format('DD/MM/YYYY') : ''}</div>
				
				<div className="row justify-content-center">
					<div className="video-responsive">
						<iframe
							width="853"
							height="auto"
							src={`${EmbedVid}${News.videoURL}`}
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
							title="Embedded youtube"
						/>
					</div>
				</div>
				<div className="d-flex flex-row justify-content-center">
						<div className="m-2">
							<TwitterShareButton url={`${PageUrl}${Urlparams.id}`}>
								<TwitterIcon size={32} round={true} />
							</TwitterShareButton>
						</div>
						<div className="m-2">
							<FacebookShareButton url={`${PageUrl}${Urlparams.id}`}>
								<FacebookIcon size={32} round={true} />
							</FacebookShareButton>
						</div>
						<div className="m-2">
							<WhatsappShareButton url={`${PageUrl}${Urlparams.id}`}>
								<WhatsappIcon size={32} round={true} />
							</WhatsappShareButton>
						</div>
					</div>
				</div>
				<div className="col-md-2 col-sm-1" />
			</div>
			<div className="row p-0 m-0">
				<div className="col-md-2 col-sm-2" />
				<div className="col-md-8 col-sm-8 lineBreaks NewsText">
					<div className="row mt-3 p-3">{News.body}</div>
					<div className="row justify-content-start p-0 m-0 mt-3">{Ads.length > 0 && <AdContainer imgSrc={Ads[0].imageURL}/>}</div>
				</div>

				<div className="col-md-2 col-sm-2" />
			</div>
			<div className="w-50">
			</div>
		</section>
	);
};

export default VideoPage;
