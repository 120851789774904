import React, { useState, useEffect } from 'react';


const AdContainer = (props) => {
    return(
        <div className="row justify-content-center p-0 m-0">
        <img className='AdsImage' src={props.imgSrc} alt=''/>
        </div>
    )
}

export default AdContainer;